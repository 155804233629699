import React, { useState, useEffect, useContext } from 'react';
import './whatsapp.css'
import DataTable from 'react-data-table-component';
// import NewTemplate from '../NewTemplate/NewTemplate'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import NoDataComponent from './NoData/NoData';
import ContactTableLoader from '../Loaders/ContactTableLoader/ContactTableLoader';
import Breadcrumbs from '../Toolbar/Breadcrumbs';

import CreateTemplate from './CreateTemplate/CreateTemplate';

import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { baseUrl } from '../../Properties/Properties';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CellTowerIcon from '@mui/icons-material/CellTower';
import HistoryIcon from '@mui/icons-material/History';
import LinkIcon from '@mui/icons-material/Link';
import QuizIcon from '@mui/icons-material/Quiz';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TemplateContext from '../../Context/TemplateContext';
import SegmentContext from '../../Context/SegmentContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1150,
  height: 580,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const Whatsapp = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  // const { tokenFromContext } = useContext(TokenContext);
  const { templateArray, setTemplateArray } = useContext(TemplateContext);
  const { segmentArray, setSegmentArray } = useContext(SegmentContext);

  const [templateData, setTemplateData] = useState([]);
  const [segmentData, setSegmentData] = useState([]);
  const [templateLoader, setTemplateLoader] = useState(true);
  const [updateTemplates, setUpdateTemplates] = useState();

  const [deleteId, setDeleteId] = useState();
  const template_URL = `${baseUrl}/whatsapp/template?userID=${userId}`;
  const delete_URL = `${baseUrl}/whatsapp/delete`;
  const segment_URL = `${baseUrl}/zumi/api/getsegment`;

  const [sortTemplateDirection, setSortTemplateDirection] = useState('desc');
  const [searchQuery, setSearchQuery] = useState('');

  const [updateId, setUpdateId] = useState(null);
  const [broadcastTemplateId, setBroadcastTemplateId] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);

  const [broadcastOpen, setBroadcastOpen] = React.useState(false);
  const handleBroadcastOpen = () => setBroadcastOpen(true);
  const handleBroadcastClose = () => setBroadcastOpen(false);
  const [activeTab, setActiveTab] = useState('broadcast');

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab
  };

  const templateTableStyles = {
    headRow: {
      style: {
        position: "sticky",
        top: 0,
        color: "black",
        color: "rgb(14, 113, 195)",
        width: "100%",
        fontSize: "1rem",
        lineHeight: "24px",
      },
    },
    headCells: {
      style: {
        // border: "1px solid blue",
        padding: "10px",
        textAlign: "left",
      },
    },
    cells: {
      style: {
        // border: "1px solid red",
        padding: "10px",
        fontSize: "0.9rem",
        color: "black",
        lineHeight: "25px",
      },
    }
  }

  const columns = [
    {
      name: t('templateName'),
      selector: row => row.templateName,
    },
    {
      name: t('category'),
      selector: row => row.category.charAt(0).toUpperCase() + row.category.slice(1).toLowerCase(),
      // minWidth: "140px",
      width: "140px"
    },
    {
      name: t('status'),
      // selector: row => row.status,
      selector: row => row.status.charAt(0).toUpperCase() + row.status.slice(1).toLowerCase(),
      // minWidth: "140px",
      width: "140px"
    },
    {
      name: t('language'),
      // selector: row => row.language,
      selector: row => {
        if (row.language === "en") {
          return "English";
        }
        if (row.language === "en_US") {
          return "English (US)";
        }
        if (row.language === "en_UK") {
          return "English (UK)";
        }
      },
      // minWidth: "140px",
      width: "140px"
    },
    {
      name: t('dateCreated'),
      selector: row => {
        const date = new Date(row.created_DateTime);
        return date.toLocaleString('en-US', {
          // weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
      },
    },
    {
      name: t('actions'),
      selector: row => row.actions = <div className="actionsButtonsCont">

        <div className="deleteBtn_cont" onClick={() => { deleteItem(row.messageId) }}>
          <DeleteIcon className='deleteIcon' style={{ height: '20px', width: '20px' }}></DeleteIcon>
        </div>
        {row.flag === 1 && (
          <div className="broadcastBtn_cont" onClick={() => openBroadcastTemplate(row.messageId)}>
            {t('broadcast')}
          </div>
        )}
        {/* {row.status === 'APPROVED' && (
          <div className="broadcastBtn_cont" onClick={() => openBroadcastTemplate(row.messageId)}>
            Broadcast
          </div>
        )} */}
        {/* <div className="broadcastBtn_cont" onClick={() => { openBroadcastTemplate(row.messageId) }}>
          {t('broadcast')}
        </div> */}
        {/* <div className="deleteBtn_cont" onClick={() => { deleteItem(row.messageId) }}>
          <DeleteIcon className='deleteIcon' style={{ height: '20px', width: '20px' }}></DeleteIcon>
        </div> */}
      </div>,
      style: {
        width: '120px'
      }
    },
  ];

  const [sortItem, setSortItem] = React.useState([
    { id: 1, value: "desc", name: t('latest') },
    { id: 2, value: "asc", name: t('oldest') },
  ]);

  // S E T   B R O A D C A S T   I D 
  function openBroadcastTemplate(id) {
    handleBroadcastOpen();
    setBroadcastTemplateId(id);
  }

  // D E L E T E  T E M P L A T E   I T E M
  const deleteItem = async (id) => {
    try {
      const response = await fetch(`${delete_URL}?id=${id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },

      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Update state after successful deletion
      setDeleteId(id);
      setTemplateData(templateData.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  // F E T C H   S E G M E N T S   D A T A 
  useEffect(() => {
    if (authToken) {

      const fetchData = async () => {
        try {
          const response = await fetch(segment_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setSegmentData(result);
        } catch (error) {
          console.log(error);
        }

      };
      fetchData();
    }
  }, []);

  // S E T T I N G   S E G M E N T   C O N T E X T 
  useEffect(() => {
    if (segmentData) {
      setSegmentArray(segmentData);
    }
  }, [segmentArray, segmentData]);


  // F E T C H   T E M P L A T E   D A T A 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(template_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
            'Content-Type': 'application/json' // Set content type if necessary
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setTemplateData(result);
      } catch (error) {
        console.log(error);
      } finally {
        setTemplateLoader(false);
      }
    };
    fetchData();
  }, [deleteId, updateTemplates]);

  // S E T T I N G   T E M P L A T E   C O N T E X T 
  useEffect(() => {
    if (templateData) {
      setTemplateArray(templateData);
    }
  }, [templateData, setTemplateArray, deleteId]);


  // S O R T   A N D   S E A R C H   T E M P L A T E   D A T A 
  const handleTemplateSort = (column, sortTemplateDirection) => {
    setSortTemplateDirection(sortTemplateDirection);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };


  const filteredAndSortedTemplateData = React.useMemo(() => {
    const filteredData = templateData.filter(item =>
      item.templateName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredData.slice().sort((a, b) => {
      if (sortTemplateDirection === 'asc') {
        return new Date(a.created_DateTime) - new Date(b.created_DateTime);
      } else {
        return new Date(b.created_DateTime) - new Date(a.created_DateTime);
      }
    });
  }, [templateData, sortTemplateDirection, searchQuery]);

  const updateTemplateData = (value) => {
    setUpdateTemplates(value);
  }

  return (
    <div className='whatsapp_main'>

      <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'broadcast' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp"
          >
            <CellTowerIcon style={{ marginRight: "8px" }} /> {t('broadcast')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'history' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/message-history"
          >
            <HistoryIcon style={{ marginRight: "8px" }} /> {t('messageHistory')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'connect' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/connect-account"
          >
            <LinkIcon style={{ marginRight: "8px" }} /> {t('connectAccount')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'automation' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/automations"
          >
            <SettingsSuggestIcon style={{ marginRight: "8px" }} /> {t('automations')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'knowledge' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/knowledge"
          >
            <QuizIcon style={{ marginRight: "8px" }} /> {t('knowledgeBase')}
          </Link>
        </div>
      </div>

      <div className='searchAndbtn_cont'>
        <div className="searchTemplate_row">
          <div className="searchSortTemplate_cont">
            <TextField
              className='searchTemplateInput'
              id="outlined-size-small"
              size="small"
              placeholder='Search Templates'
              onChange={handleSearch}
            />
            <Select
              size="small"
              className='sortTemplateInput'
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={sortTemplateDirection}
              onChange={e => handleTemplateSort(null, e.target.value)}
            >
              {
                sortItem.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                  )
                })
              }
            </Select>
          </div>

          <div className="templateRowBtn_cont">
            <button className='btnFill' onClick={handleOpen}>
              {t('newTemplate')}
            </button>

            {/* M O D A L   T O   C R E A T E   T E M P L A T E */}
            <Modal
              className='templateStyleMobile'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <CreateTemplate btnText="Submit" updateTemplateData={updateTemplateData} closeModal={handleClose} broadcastTemplateId={null} updateId={updateId} templateData={templateData}></CreateTemplate>
              </Box>
            </Modal>

            {/* M O D A L   F O R   B R O A D C A S T  */}
            <Modal
              className='templateStyleMobile'
              open={broadcastOpen}
              onClose={handleBroadcastClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <CreateTemplate btnText="Broadcast" updateTemplateData={updateTemplateData} closeModal={handleBroadcastClose} broadcastTemplateId={broadcastTemplateId} templateData={templateArray}></CreateTemplate>
              </Box>
            </Modal>
          </div>
        </div>
      </div>

      <div className='tableMain_cont'>
        <div className='table_cont'>
          {templateLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              customStyles={templateTableStyles}
              noDataComponent={<NoDataComponent />}
              data={filteredAndSortedTemplateData}
              defaultSortField="created_DateTime"
              defaultSortAsc={sortTemplateDirection === 'asc'}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default Whatsapp;