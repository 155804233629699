import React, { useState } from 'react';
import './NewUser.css';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const NewUser = ({ roles, updateUsersData, showAlert, closeModal }) => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const createUser_URL = `${baseUrl}/zumi/api/users/register`;
  const [updateUsers, setUpdateUsers] = useState(false);

  const [passAlert, setPassAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [userCreatedAlert, setUserCreatedAlert] = useState(false);
  const [userExistAlert, setUserExistAlert] = useState(false);

  const [newUser, setNewUser] = useState({
    contactPerson: "",
    firstName: "",
    lastName: "",
    emailId: "",
    contactNumber: "",
    companyName: "",
    password: "",
    roleId: "",
  })

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // if (name === 'password' && value.length < 8) {
    //   setPass8CharError("Password must have atleast 8 characters.")
    //   return;
    // }
    setNewUser((prev) => ({ ...prev, [name]: value }));
    if (name === 'password' && value.length < 8) {
      setPassAlert(true);
      setUserExistAlert(false);
    }
    if (name === 'password' && value.length > 8) {
      setPassAlert(false);
    }
  }

  // H A N D L E   P A S S W O R D   V I S I B I L I T Y

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  // H A N D L E   N E W   U S E R
  const handleAddUser = async (e) => {
    e.preventDefault();
    const { firstName, lastName, emailId, password, contactNumber, companyName, roleId } = newUser;
    const contactPerson = `${firstName} ${lastName}`;
    const dataToSend = {
      emailId,
      password,
      contactNumber,
      contactPerson,
      companyName,
      roleId,
    };

    try {
      const response = await fetch(createUser_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 409 && errorData.result === 'fail' && errorData.message === 'User already exists') {
          setUserExistAlert(true);
          return; // Keep the modal open
        } else {
          closeModal();
          showAlert("User not created.", '#FFE4C0', '#FD5D5D');
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = await response.json();
      console.log('User added successfully');
      setUpdateUsers(!updateUsers);
      updateUsersData(updateUsers);
      setUserCreatedAlert(true);
      setUserExistAlert(false);
      closeModal();
      showAlert("User added successfully.", '#d4f4e6', '#77d98b');
    } catch (err) {
      console.error('Error posting data:', err);
      closeModal();
      showAlert("User not created due to an error.", '#FFE4C0', '#FD5D5D');
    } finally {
      setNewUser({
        contactPerson: "",
        firstName: "",
        lastName: "",
        emailId: "",
        contactNumber: "",
        companyName: "",
        password: "",
        roleId: "",
      });
    }
  };

  const mandatoryError = !newUser.firstName || !newUser.lastName || !newUser.emailId || !newUser.contactNumber || !newUser.roleId || !newUser.password;
  return (
    <div className='newUserMain_cont'>
      <div className="newRoleHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('addNewUser')}
          </span>
        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="newUserForm_cont">
        {/* R O W 1 */}
        <div className="newUserRow">
          <div className="newUserFormInput_cont" style={{ marginRight: "15px" }}>
            <label htmlFor="firstName" className="welcomeFormLabel">{t('firstName')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='text'
              name="firstName"
              id="outlined-basic"
              placeholder="First Name"
              variant="outlined"
              value={newUser.firstName}
              onChange={handleChange}
            />
          </div>

          <div className="newUserFormInput_cont">
            <label htmlFor="lastName" className="welcomeFormLabel">{t('lastName')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='text'
              name="lastName"
              id="outlined-basic"
              placeholder="Last Name"
              variant="outlined"
              value={newUser.lastName}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* R O W 2 */}
        <div className="newUserRow">
          <div className="newUserFormInput_cont" style={{ marginRight: "15px" }}>
            <label htmlFor="email" className="welcomeFormLabel">{t('email')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='email'
              name="emailId"
              id="outlined-basic"
              placeholder="Enter Email"
              variant="outlined"
              value={newUser.emailId}
              onChange={handleChange}
            />
          </div>

          <div className="newUserFormInput_cont">
            <label htmlFor="phone" className="welcomeFormLabel">{t('phone')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='number'
              name="contactNumber"
              id="outlined-basic"
              placeholder="Enter phone number"
              variant="outlined"
              value={newUser.contactNumber}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* R O W 3 */}
        <div className="newUserRow">
          <div className="newUserFormInput_cont" style={{ marginRight: "15px" }}>
            <label htmlFor="password" className="welcomeFormLabel">{t('password')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              // type='password'
              type={showPassword ? 'text' : 'password'}
              name="password"
              id="password"
              placeholder="Enter password"
              variant="outlined"
              value={newUser.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="newUserFormInput_cont">
            <label htmlFor="role" className="welcomeFormLabel">{t('selectRole')}  <span className="asterisk">*</span></label>
            <Autocomplete
              options={roles}
              getOptionLabel={(option) => option.roleName}
              value={roles.find(item => item.roleId === newUser.roleId) || null}
              onChange={(event, newValue) => {
                handleChange({ target: { name: 'roleId', value: newValue ? newValue.roleId : '' } });
              }}
              renderInput={(params) => (
                <TextField style={{ borderRadius: "10px" }} {...params} placeholder="Select Role" variant="outlined" />
              )}
              style={{ width: '100%', borderRadius: '10px' }}
            />
          </div>
        </div>
        <div className="addUserBtn_cont">
          {mandatoryError && (
            <p style={{ color: 'red', fontSize: "12px", }}>Please fill the mandatory fields.</p>
          )}
          <button className='btnFill' disabled={mandatoryError || passAlert} onClick={handleAddUser}>{t('addUser')}</button>
        </div>
      </div>

      <div className="newUserAlert_cont">
        {passAlert &&
          <Alert severity="error" className='loginAlert' id='incorrectAlert'>Password must have atleast 8 characters.</Alert>
        }
        {userExistAlert &&
          <Alert severity="error" className='loginAlert' id='incorrectAlert'>User already exists.</Alert>
        }
        {userCreatedAlert &&
          <Alert severity="success" className='loginAlert' id='incorrectAlert'>User Created Successfully.</Alert>
        }
      </div>
    </div>
  )
}

NewUser.propTypes = {
  handleCloseUsersModal: propTypes.func,
};

export default NewUser;

