import React, { useState } from 'react';
import './ConnectAccount.css';
import { useTranslation } from 'react-i18next';

import CellTowerIcon from '@mui/icons-material/CellTower';
import HistoryIcon from '@mui/icons-material/History';
import LinkIcon from '@mui/icons-material/Link';
import QuizIcon from '@mui/icons-material/Quiz';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';



import { Link } from 'react-router-dom';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';

const ConnectAccount = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('connect');

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab
  };

  return (
    <div className='connectMain_cont'>
      {/* <Breadcrumbs /> */}
      <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'broadcast' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp"
          >
            <CellTowerIcon style={{ marginRight: "8px" }} /> {t('broadcast')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'history' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/message-history"
          >
            <HistoryIcon style={{ marginRight: "8px" }} /> {t('messageHistory')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'connect' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/connect-account"
          >
            <LinkIcon style={{ marginRight: "8px" }} /> {t('connectAccount')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'automation' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/automations"
          >
            <SettingsSuggestIcon style={{ marginRight: "8px" }} /> {t('automations')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'knowledge' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/knowledge"
          >
            <QuizIcon style={{ marginRight: "8px" }} /> {t('knowledgeBase')}
          </Link>
        </div>
      </div>
      <div className="connect_cont">
        <h3 style={{ color: "rgb(52, 71, 103)", marginBottom: "10px" }}>{t('connectAccountMainHead')}</h3>
        <p className='smallPara'>{t('connectAccountPara1')}</p>

        <div className="connectSub1_cont">
          <h4>{t('connectContactSubHead')}</h4>
          <p className='smallPara'>{t('connectAccountPara2')}</p>
          <p className='smallPara'>{t('connectAccountPara3')}</p>

        </div>
        <div className="connectSub2_cont">
          <p className='smallPara'>{t('connectAccountPara4')} <a href="">{t('backupNow')}</a></p>
          <p className='smallPara'>{t('connectAccountPara5')} <a href="">{t('learnMore')}</a></p>
        </div>

        <div className="connectBtn_cont">
          <Link to="/home/whatsapp/connect-account/connect-step1" className='btnFill'>{t('connect')}</Link>
        </div>


      </div>
    </div>
  )
}

export default ConnectAccount;