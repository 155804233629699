import React, { useEffect, useState } from 'react';
import './ViewBroadcast.css';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import LocationSelect from '../../Dashboard/LocationSelect/LocationSelect';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';

const ViewBroadcast = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const broadcast_URL = `${baseUrl}/zumi/api/broadcast`;
  const [broadcastLoader, setBroadcastLoader] = useState(true);
  const [broadcastData, setBroadcastData] = useState([]);

  const [sortedBroadcastData, setSortedBroadcastData] = useState([])

  const columns = [
    {
      name: t('srNo'),
      selector: (row, rowIndex) => rowIndex + 1,
      width: "80px",
    },
    {
      name: t('dealerName'),
      selector: row => row.supplierName,
      width: "170px",
    },
    {
      name: "Dealer Type",
      selector: row => row.dealerType,
    },
    {
      name: "Markup",
      selector: row => row.markup,
    },
    {
      name: t('dateCreated'),
      selector: row => {
        const date = new Date(row.createddate);
        return date.toLocaleString('en-US', {
          // weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
      },
    },
  ];

  // G E T   B R O A D C A S T   D E T A I L S
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        setBroadcastLoader(true);
        try {
          const response = await fetch(broadcast_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setBroadcastData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setBroadcastLoader(false);
        }

      };
      fetchData();
    }
  }, []);


  // S E T    S O R T E D   B R O A D C A S T    D A T A
  useEffect(() => {
    if (broadcastData.length > 0) {
      const sortedData = [...broadcastData].sort((a, b) => new Date(b.createddate) - new Date(a.createddate));
      setSortedBroadcastData(sortedData);
    }
  }, [broadcastData]);



  return (
    <div className='viewBroadcastMain_cont'>
      <Breadcrumbs />
      <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          {broadcastLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={sortedBroadcastData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ViewBroadcast;