import React, { useEffect, useState } from 'react';
import './SearchProduct.css';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import { useTranslation } from 'react-i18next';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';

const SearchProduct = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem("authToken");
  const [isTableVisible, setIsTableVisible] = useState(false);
  const searchProduct_URL = `${baseUrl}/zumi/api/supplier-result`;
  const [productResponse, setProductResponse] = useState();
  const [brand, setBrand] = useState();
  const [category, setCategory] = useState();
  const [supplier, setSupplier] = useState();
  const [broadcast, setBroadcast] = useState();
  const [contactInfo, setcontactInfo] = useState();
  const [productFilters, setProductFilters] = useState({
    filterType: "",
    keywords: "",
  })

  const columns = [
    {
      name: t('srNo'),
      selector: (row, rowIndex) => rowIndex + 1,
      width: "70px",
    },
    {
      name: t('supplierName'),
      selector: row => row.supplierName,
      width: "170px",
    },
    // {
    //   name: "Download Link",
    //   selector: row => row.itemName,
    //   width: "120px",
    // },
    {
      name: t('quotationNum'),
      selector: row => row.quoteFile,
      // width: "200px",
    },
    {
      name: t('quotationFile'),
      selector: row => row.quoteFile,
      // width: "200px",
    },
    {
      name: t('createdDate'),
      selector: row => row.createddate,
      width: "170px",
    },
  ];

  const viewTable = () => {
    setIsTableVisible(!isTableVisible);
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setProductFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const filtersJson = JSON.stringify(productFilters);

    fetch(searchProduct_URL, {
      method: 'POST',
      body: filtersJson,
      headers: {
        'Content-Type': 'application/json', // Add Content-Type header
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
      }
    }).then(response => {
      if (response.ok) { // Check if the response status is in the range 200-299
        return response.json(); // Parse the JSON from the response
      } else {
        let message = `Error: ${response.status} ${response.statusText}`;
        // alert(message);
        throw new Error(message);
      }
    }).then(data => {
      // console.log(data);
      setProductResponse(data);
    }).catch(error => {
      console.error('There was a problem with the fetch operation:', error); // Log any errors
    });
  };


  useEffect(() => {
    if (productResponse) {
      console.log("Product Response array: ", productResponse);
      setBrand(productResponse.listOfBrand);
      setBroadcast(productResponse.listOfBroadcast);
      setCategory(productResponse.listOfCategory);
      setSupplier(productResponse.listOfSupplier);
      setcontactInfo(productResponse.listOfSupplier[0])

    }
  }, [productResponse]);


  useEffect(() => {
    if (brand) {
      console.log("Brands : ", brand);
    }
    if (broadcast) {
      console.log("Broadcast : ", broadcast);
    }
    if (category) {
      console.log("Category : ", category);
    }
    if (supplier) {
      console.log("Supplier : ", supplier);
    }
    if (contactInfo) {
      console.log("Contact Info : ", contactInfo);
    }
  }, [brand, broadcast, category, supplier, contactInfo]);


  const isSearchDisabled = !productFilters.filterType || !productFilters.keywords;

  return (
    <div className='searchProductMain_cont'>
      <Breadcrumbs />
      {/* <h2 style={{ textAlign: "center" }}>Search Product</h2> */}
      <div className="searchProductCont1">
        <p className='searchProductLabel'>{t('searchProduct')}</p>
        <form className="searchProductForm_cont">
          <div className="searchProductFrom_Row">
            <div className="productFilter_cont">
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name='filterType'
                value={productFilters.filterType}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select Filter</em>;
                  }
                  return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                }}
              >
                <MenuItem value="product">Product</MenuItem>
              </Select>
            </div>
            <div className="keywords_cont">
              <TextField
                fullWidth
                variant="outlined"
                id="outlined-basic"
                placeholder='Type something'
                name="keywords"
                value={productFilters.keywords}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="productSearchBtn_cont">
            <button
              className='btnFill'
              onClick={handleSubmit}
              disabled={isSearchDisabled}
              style={{
                border: isSearchDisabled ? "1px solid #EEEEEE" : "#17c1e8",
                backgroundColor: isSearchDisabled ? '#EEEEEE' : '#17c1e8',
                color: isSearchDisabled ? 'gray' : '#fff',
              }}
            >
              {t('searchProduct')}
            </button>
          </div>
        </form>


      </div>

      {/* Details Container */}

      {productResponse && (
        <div className="searchProductCont2">
          <div className="productInfoCards_cont">
            {brand && (
              <div className="searchBrand_cont">
                <h3 className='searchProductCardHead'>{t('brand')}</h3>
                {brand.map((item, index) => {
                  return (
                    <p>{item}</p>
                  )
                })}

              </div>
            )}
            {category && (
              <div className="searchCategory_cont">
                <h3 className='searchProductCardHead'>{t('category')} </h3>
                {category.map((item, index) => {
                  return (
                    <p key={index}>{item}</p>
                  )
                })}
              </div>
            )}

            {broadcast && (
              <div className="searchContactInfo_cont">
                <h3 className='searchProductCardHead'>{t('contactInfo')}</h3>
                <p><span>{t('name')} :</span> {contactInfo.supplierName} </p>
                <p><span>{t('email')} :</span> {contactInfo.supplierEmail} </p>
                <p><span>{t('phone')} :</span> {contactInfo.phone} </p>
                <p><span>Dealer Type :</span> {contactInfo.dealerType} </p>

              </div>
            )}
          </div>

          <div className="viewMoreBtn_cont">
            <button className='btnNotFill viewMoreBtn' onClick={viewTable}>{t('viewMore')}</button>
          </div>
        </div>
      )}


      {isTableVisible && (
        <div className="revenueTableMain_cont">
          <div className='revenueTable_cont'>

            <DataTable
              columns={columns}
              data={supplier}
              customStyles={tableStyles}
              pagination
            />
          </div>
        </div>
      )}



    </div>
  )
}

export default SearchProduct;