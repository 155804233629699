import React, { useState, useEffect, useContext } from 'react';
import './MyAccount.css';
// import CompanyLogo from '../../../src/assets/zumiBrandImg.png';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
// import { useNavigate, Link } from 'react-router-dom';
// import Modal from '@mui/material/Modal';
// import Box from '@mui/material/Box';
// import Payment from '../Payment/Payment';
import SellIcon from '@mui/icons-material/Sell';
import ImageIcon from '@mui/icons-material/Image';


import { useTranslation } from 'react-i18next';

import i18n from 'i18next';
import { CurrencyContext } from '../../Context/CurrencyContext';
import { BrandLogoContext } from '../../Context/BrandLogoContext';
import { SubscriptionPlanContext } from '../../Context/SubscriptionPlanContext';


// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   height: 500,
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   borderRadius: "15px"
//   // margin: 'auto',
//   // width: '100%',
//   // height: '100%',
//   // border: '1px solid blue',
// };


const timezones = [
  {
    region: "Africa",
    timezones: [
      { id: 15, timezone: "Africa/Johannesburg" }
    ]
  },
  {
    region: "America",
    timezones: [
      { id: 14, timezone: "America/Argentina/Buenos_Aires" },
      { id: 1, timezone: "America/New_York" },
      { id: 2, timezone: "America/Los_Angeles" },
      { id: 3, timezone: "America/Chicago" },
      { id: 4, timezone: "America/Denver" },
      { id: 5, timezone: "America/Anchorage" }
    ]
  },
  {
    region: "Asia",
    timezones: [
      { id: 9, timezone: "Asia/Kolkata" },
      { id: 10, timezone: "Asia/Tokyo" },
      { id: 11, timezone: "Asia/Dubai" },
      { id: 17, timezone: "Asia/Kathmandu" },
      { id: 18, timezone: "Asia/Singapore" }
    ]
  },
  {
    region: "Australia",
    timezones: [
      { id: 12, timezone: "Australia/Sydney" }
    ]
  },
  {
    region: "Europe",
    timezones: [
      { id: 13, timezone: "Europe/Berlin" },
      { id: 7, timezone: "Europe/London" },
      { id: 8, timezone: "Europe/Paris" },
      { id: 16, timezone: "Europe/Moscow" }
    ]
  },
  {
    region: "Pacific",
    timezones: [
      { id: 6, timezone: "Pacific/Honolulu" }
    ]
  }
];


const plansData = [
  // {
  //   "General": [
  //     { id: 1, featureName: "Users Included", growth: "5 Users", pro: "5 Users", business: "5 Users" },
  //     { id: 2, featureName: "Additional User Fee", growth: "₹699", pro: "₹1299", business: "₹3999" },
  //     { id: 3, featureName: "Discount on Zumi Conversation", growth: "N/A", pro: "N/A", business: "5%" }
  //   ]
  // },
  {
    "Whatsapp And Zumi Configuration": [
      { id: 1, featureName: "Official Whatsapp API", growth: "1", pro: "1", business: "1" },
      { id: 2, featureName: "Whatsapp Onboarding", growth: "1", pro: "1", business: "1" },
      { id: 3, featureName: "WhatsApp green tick verification assistance", growth: "1", pro: "1", business: "1" },
      { id: 4, featureName: "Website chat widget", growth: "0", pro: "1", business: "1" },
      { id: 5, featureName: "Meta Business Verification Assistance", growth: "1", pro: "1", business: "1" }
    ]
  },
  {
    "Contact Management": [
      { id: 1, featureName: "Import and Export Contacts", growth: "1", pro: "1", business: "1" },
      { id: 2, featureName: "Contact Attributes", growth: "1", pro: "1", business: "1" },
      { id: 3, featureName: "Click to WhatsApp ads (CTWA) analytics", growth: "0", pro: "1", business: "1" }
    ]
  },
  {
    "Marketing": [
      { id: 1, featureName: "Broadcasts", growth: "1", pro: "1", business: "1" },
      { id: 2, featureName: "Broadcast Scheduling (Onetime)", growth: "0", pro: "1", business: "1" },
      { id: 3, featureName: "Broadcast sequences", growth: "0", pro: "1", business: "1" }
    ]
  }
];


const MyAccount = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { currency, setCurrency } = useContext(CurrencyContext);
  const { brandLogo } = useContext(BrandLogoContext);
  const { subscriptionPlan } = useContext(SubscriptionPlanContext);

  const authToken = localStorage.getItem('authToken');
  const [activeTab, setActiveTab] = useState('month');
  const [langName, setLangName] = useState('');
  const [timeZone, setTimeZone] = useState("");

  // const [currency, setCurrency] = useState("AUD");
  const plans_URL = `${baseUrl}/zumi/api/plans`
  const [plans, setPlans] = useState([]);

  const [selectedLogo, setSelectedLogo] = useState("");
  const [logoSrc, setLogoSrc] = useState(null);

  const [logoTypeError, setLogoTypeError] = useState('');
  const [logoSizeError, setLogoSizeError] = useState('');

  const [eway_url, setEway_url] = useState("");
  const [loadingButton, setLoadingButton] = useState(null);

  const [payment, setPaymentFrom] = useState(
    {
      paymentFrom: "MyAccount",
      planDuration: "",
      selectedPlanId: ""
    })

  const [planPrice, setPlanPrice] = useState(0);
  const [planName, setPlanName] = useState("");

  const [openPaymentModal, setOpenPaymentModal] = React.useState(false);

  const handleOpenPaymentModal = (price, name) => {
    setPlanPrice(price);
    setPlanName(name);
    setOpenPaymentModal(true);
  }

  const handleClosePaymentModal = () => setOpenPaymentModal(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLangChange = (e) => {
    setLangName(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const handleTimezoneChange = (e) => {
    setTimeZone(e.target.value);
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  }

  const renderValue = (value) => {
    if (value === "1") {
      return <CheckIcon style={{ color: "blue" }} />;
    } else if (value === "0") {
      return <ClearIcon style={{ color: "red" }} />;
    } else {
      return value;
    }
  };

  // E W A Y   P A Y M E N T
  const handleEwayPayment = (amount, planName, planId) => {
    setLoadingButton(planName);
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${baseUrl}/zumi/api/payment/request-access-code?totalAmount=${amount}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          // Update the paymentFrom state with the new values
          if (activeTab) {
            setPaymentFrom(prevState => {
              const updatedState = { ...prevState, planDuration: activeTab };
              localStorage.setItem("paymentFrom", JSON.stringify(updatedState));  // Store the updated state
              return updatedState;
            });
          }

          if (planId) {
            setPaymentFrom(prevState => {
              const updatedState = { ...prevState, selectedPlanId: planId };
              localStorage.setItem("paymentFrom", JSON.stringify(updatedState));  // Store the updated state
              return updatedState;
            });
          }

          // localStorage.setItem('selectedPlanId', planId);
          // localStorage.setItem('planDuration', activeTab);
          const result = await response.text();
          setEway_url(result);
          setTimeout(() => {
            window.location.href = result; // Redirect to the payment URL
          }, 1000); // 1-second delay (adjust as needed)

        }
        catch (error) {
          console.log(error);
        }
        finally {
          // setLoadingButton(null); // Reset loading button state
        }
      };
      fetchData();
    }
  }

  // R E D I R E C T    T O   E W A Y
  useEffect(() => {
    if (eway_url) {
      window.location.href = eway_url;
      // setLoadingButton(null); // Reset loading button state
    }
  }, [eway_url]);


  // L O G O   H A N D L E R 
  const handleLogoChange = (event) => {
    const image = event.target.files[0];

    if (image) {
      // Define allowed image types
      const validTypes = ['image/jpg', 'image/png'];

      // Define the maximum allowed size (in bytes)
      const maxSize = t("maxImageHeaderSize") * 1024 * 1024; // 30 MB

      if (validTypes.includes(image.type)) {
        if (image.size <= maxSize) {
          setSelectedLogo(image);
          setLogoTypeError('');

          const reader = new FileReader();

          reader.onloadend = () => {
            setLogoSrc(reader.result);
          };

          reader.readAsDataURL(image);
          setLogoTypeError('');
          setLogoSizeError('');
        } else {
          setLogoSrc(null);
          setSelectedLogo(null);
          setLogoSizeError(t("imageSizeError"));
        }
      } else {
        setLogoSrc(null);
        setSelectedLogo(null);
        setLogoTypeError(t("imageError"));
      }
    } else {
      setSelectedLogo(null);
      setLogoSrc(null);
      setLogoTypeError("");
      setLogoSizeError("");
    }
  };

  // C H A N G E   L O G O
  useEffect(() => {
    if (selectedLogo) {
      changeLogo();
    }
  }, [selectedLogo]);

  const changeLogo = () => {
    const formData = new FormData();
    formData.append('file', selectedLogo);

    for (let entry of formData.entries()) {
      console.log(entry[0] + ': ' + entry[1]);
    }

    try {
      const response = fetch(`${baseUrl}/zumi/api/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: formData,
      });
      if (response.ok) {
        console.log('Logo changed successfully');
      } else {
        console.error('Failed to change logo.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  // G E T   P L A N S
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(plans_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setPlans(result);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, []);


  useEffect(() => {
    // Get the user's local timezone using Intl API
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Set the default value based on the user's timezone
    const timezoneId = timezones
      .flatMap(region => region.timezones)
      .find(tz => tz.timezone === userTimezone)?.id;

    if (timezoneId) {
      setTimeZone(timezoneId);
    }
  }, []);


  return (
    <div className='myAccountMain_cont'>
      <div className="myAccountSub_cont1">
        <div className="companyLogoMain_cont">
          <h3 className='' style={{ textAlign: "center" }}>{t("companyLogo")}</h3>

          <div className="companyLogo_cont" style={{ textAlign: "center" }}>
            {logoSrc ? (
              <img src={logoSrc} alt="Selected Logo" />
            ) : (
              brandLogo && <img src={brandLogo} alt="brandImg" />
            )}
          </div>
          {
            subscriptionPlan === 3 && (
              <div className="changeLogoBtn_cont">
                <input
                  type='file'
                  accept=".png"
                  className='uploadFileBtn smsInputField'
                  name="smsContactFile"
                  id="uploadBtn"
                  onChange={handleLogoChange}
                />
                <label
                  htmlFor="uploadBtn"
                  className='changeLogoBtn'
                >
                  <ImageIcon /> {t("changeLogo")}
                </label>
              </div>
            )
          }
          {
            subscriptionPlan === 3 && (
              <div className="logoErrors_cont">
                {logoSrc && <p style={{ color: "green", fontSize: "13px" }}>{selectedLogo.name}</p>}
                {logoTypeError && <p style={{ color: "red", fontSize: "13px" }}>{logoTypeError}</p>}
                {logoSizeError && <p style={{ color: "red", fontSize: "13px" }}>{logoSizeError}</p>}
              </div>
            )
          }
        </div>
        <div className="myAccountFormCont">
          <div className="myAccInput_cont" style={{ marginRight: "10px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t("customerTimezone")}  <span className="asterisk">*</span></label>
            <Select
              className="myAccInput_cont"
              value={timeZone}
              onChange={handleTimezoneChange}
              labelId="timezone-select-label"
              id="timezone-select"
              size="small"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 400,
                    overflowY: 'auto',
                    maxWidth: 500
                  }
                }
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              {timezones.map((region) => (
                <React.Fragment key={region.region}>
                  <MenuItem disabled style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {region.region}
                  </MenuItem>

                  {region.timezones.map((tz) => (
                    <MenuItem key={tz.id} value={tz.id}>
                      {tz.timezone}
                    </MenuItem>
                  ))}
                </React.Fragment>
              ))}
            </Select>


          </div>
          <div className="myAccountFormRow">
            <div className="myAccInput_cont" style={{ marginRight: "10px" }}>
              <label htmlFor="language" className="welcomeFormLabel">{t("language")}  <span className="asterisk">*</span></label>
              <Select
                className='myAccInput_cont'
                size='small'

                labelId="demo-select-small-label"
                id="demo-select-small"
                name='locales'
                value={langName}
                onChange={handleLangChange}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === '') {
                    return "English";
                  }
                  if (selected === 'en') {
                    return "English";
                  }
                  if (selected === "fr") {
                    return "French";
                  }
                  if (selected === "hi") {
                    return "Hindi";
                  }
                  return selected;
                }}
              >
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="fr">French</MenuItem>
                <MenuItem value="hi">Hindi</MenuItem>
              </Select>
            </div>
            <div className="myAccInput_cont">
              <label htmlFor="currency" className="welcomeFormLabel">{t("currency")}  <span className="asterisk">*</span></label>
              <Select
                className='myAccInput_cont'
                size='small'

                labelId="demo-select-small-label"
                id="demo-select-small"
                name='currency'
                value={currency}
                onChange={handleCurrencyChange}
              >
                <MenuItem value="AUD">AUD</MenuItem>
                <MenuItem value="INR">INR</MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </div>

      {/* P R I C I N G    H E A D E R */}
      <div className="accPriceHeaderMain_cont">
        <div className="accPriceHeaderCol1">
          <p>{t("filterPara")}</p>
          <div className="accFilterCont">
            <div
              className={`monthTab centerElm ${activeTab === 'month' ? 'monthTabActive' : ''}`}
              onClick={() => handleTabClick('month')}>
              {t("month")}
            </div>
            <div
              className={`yearTab centerElm ${activeTab === 'year' ? 'monthTabActive' : ''}`}
              onClick={() => handleTabClick('year')}
            >
              {t("annual")}
            </div>
          </div>
          <div className="plansOfferCont centerElm">
            <SellIcon style={{ height: "50%" }} />{t("discountPara")}
          </div>
        </div>

        {plans.map((item) => (
          <div key={item.plan_id} className="accPriceHeaderCol">
            <div className="accPlanDetailsCont">
              <h2>{item.plan_name}</h2>
              <p>{item.description}</p>
            </div>
            <div className="accPlanCardBtn_cont">
              {activeTab === "month" && (
                <p className='accountPricePara'>
                  <span>$ {item.price_month} {item.currency}</span> / {activeTab}
                </p>
              )}

              {activeTab === "year" && (
                <p className='accountYearPricePara'>
                  <span style={{ textDecoration: 'line-through' }}>$ {item.price_year} {item.currency}</span> / {activeTab}
                </p>
              )}
              {activeTab === "year" && (
                <p className='accountPricePara'>
                  <span>$ {item.price_year} {item.currency}</span> / {activeTab}
                </p>
              )}

              {
                activeTab === "month" && (
                  <button
                    className='btnFill choosePlanBtn'
                    onClick={() => handleEwayPayment(item.price_month, item.plan_name, item.plan_id)}
                    disabled={loadingButton}
                    style={{
                      border: loadingButton ? "1px solid #EEEEEE" : "#17c1e8",
                      backgroundColor: loadingButton ? '#EEEEEE' : '#17c1e8',
                      color: loadingButton ? 'gray' : '#fff',
                    }}
                  >
                    {loadingButton === item.plan_name ? <div className="loader"></div> : "Choose Plan"}
                  </button>
                )
              }
              {
                activeTab === "year" && (
                  <button
                    className='btnFill choosePlanBtn'
                    onClick={() => handleEwayPayment(item.price_year, item.plan_name, item.plan_id)}
                    disabled={loadingButton}
                    style={{
                      border: loadingButton ? "1px solid #EEEEEE" : "#17c1e8",
                      backgroundColor: loadingButton ? '#EEEEEE' : '#17c1e8',
                      color: loadingButton ? 'gray' : '#fff',
                    }}
                  >
                    {loadingButton === item.plan_name ? <div className="loader"></div> : "Choose Plan"}
                  </button>
                )
              }
            </div>
          </div>
        ))}

        {/* {plans.map((item) => (
          <div key={item.plan_id} className="accPriceHeaderCol">
            <div className="accPlanDetailsCont">
              <h2>{item.plan_name}</h2>
              <p>{item.description}</p>
            </div>
            <div className="accPlanCardBtn_cont">
              {activeTab === "month" && (
                <p className='accountPricePara'>
                  <span>$ {item.price_month} {item.currency}</span> / {activeTab}
                </p>
              )}
              {activeTab === "year" && (
                <p className='accountPricePara'>
                  <span>
                    $ {Math.round(item.price_year * 0.8)} {item.currency}
                  </span> / {activeTab}
                </p>
              )}

              {activeTab === "month" && (
                <button
                  className='btnFill choosePlanBtn'
                  onClick={() => handleEwayPayment(item.price_month, item.plan_name, item.plan_id)}
                  disabled={loadingButton}
                  style={{
                    border: loadingButton ? "1px solid #EEEEEE" : "#17c1e8",
                    backgroundColor: loadingButton ? '#EEEEEE' : '#17c1e8',
                    color: loadingButton ? 'gray' : '#fff',
                  }}
                >
                  {loadingButton === item.plan_name ? <div className="loader"></div> : "Choose Plan"}
                </button>
              )}
              {activeTab === "year" && (
                <button
                  className='btnFill choosePlanBtn'
                  onClick={() => handleEwayPayment(Math.round(item.price_year * 0.8), item.plan_name, item.plan_id)}
                  disabled={loadingButton}
                  style={{
                    border: loadingButton ? "1px solid #EEEEEE" : "#17c1e8",
                    backgroundColor: loadingButton ? '#EEEEEE' : '#17c1e8',
                    color: loadingButton ? 'gray' : '#fff',
                  }}
                >
                  {loadingButton === item.plan_name ? <div className="loader"></div> : "Choose Plan"}
                </button>
              )}
            </div>
          </div>
        ))} */}



        {/* <div className="accPriceHeaderCol">
          <div className="accPlanDetailsCont">
            <h2>{t("planName1")}</h2>
            <p>{t("planPara1")}</p>
          </div>
          <div className="accPlanCardBtn_cont">
            {currency === "AUD" && activeTab === "month" && (<p className='accountPricePara'><span>$ 49 AUD</span> / month</p>)}
            {currency === "INR" && activeTab === "month" && (<p className='accountPricePara'><span>₹ 2755 INR</span> / month</p>)}
            {currency === "AUD" && activeTab === "year" && (<p className='accountPricePara'><span>$ 470 AUD</span> / month</p>)}
            {currency === "INR" && activeTab === "year" && (<p className='accountPricePara'><span>₹ 26450 INR</span> / month</p>)}
            <Link
              className='btnFill choosePlanBtn'
              disabled={loadingButton !== null}
              onClick={() => handleEwayPayment(49, "starter")}
            >
              {loadingButton === "starter" ? "Please wait..." : "Choose Plan"}
            </Link>
          </div>
        </div>
        <div className="accPriceHeaderCol">
          <div className="accPlanDetailsCont">
            <h2>{t("planName2")}</h2>
            <p>{t("planPara2")}</p>
          </div>
          <div className="accPlanCardBtn_cont">
            {currency === "AUD" && activeTab === "month" && (<p className='accountPricePara'><span>$ 99 AUD</span> / month</p>)}
            {currency === "INR" && activeTab === "month" && (<p className='accountPricePara'><span>₹ 5565 INR</span> / month</p>)}
            {currency === "AUD" && activeTab === "year" && (<p className='accountPricePara'><span>$ 950 AUD</span> / month</p>)}
            {currency === "INR" && activeTab === "year" && (<p className='accountPricePara'><span>₹ 54290 INR</span> / month</p>)}
            <Link
              className='btnFill choosePlanBtn'
              disabled={loadingButton !== null}
              onClick={() => handleEwayPayment(99, "advanced")}
            >
              {loadingButton === "advanced" ? "Please wait..." : "Choose Plan"}
            </Link>
          </div>
        </div>
        <div className="accPriceHeaderCol">
          <div className="accPlanDetailsCont">
            <h2>{t("planName3")}</h2>
            <p>{t("planPara3")}</p>
          </div>
          <div className="accPlanCardBtn_cont">
            {currency === "AUD" && activeTab === "month" && (<p className='accountPricePara'><span>$ 299 AUD</span> / month</p>)}
            {currency === "INR" && activeTab === "month" && (<p className='accountPricePara'><span>₹ 16805 INR</span> / month</p>)}
            {currency === "AUD" && activeTab === "year" && (<p className='accountPricePara'><span>$ 2870 AUD</span> / month</p>)}
            {currency === "INR" && activeTab === "year" && (<p className='accountPricePara'><span>₹ 161330 INR</span> / month</p>)}
            <Link
              className='btnFill choosePlanBtn'
              disabled={loadingButton !== null}
              onClick={() => handleEwayPayment(299, "ultimate")}
            >
              {loadingButton === "ultimate" ? "Please wait..." : "Choose Plan"}
            </Link>
          </div>

          <Modal
            className='templateStyleMobile'
            open={openPaymentModal}
            onClose={handleClosePaymentModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Payment btnText="Submit" planPrice={planPrice} planName={planName} closeModal={handleClosePaymentModal}></Payment>
            </Box>
          </Modal>
        </div> */}
      </div>

      {/* P R I C I N G   T A B L E */}

      <div className="pricingTableCont">
        {plansData.map((category, index) => {
          const categoryName = Object.keys(category)[0];
          const features = category[categoryName];
          return (
            <div key={index} className='pricingCatCont'>
              <div className="accPriceCatHeader">
                <div className="accPriceCatName">
                  <p>{categoryName}</p>
                </div>
                <div className="accOption"></div>
                <div className="accOption"></div>
                <div className="accOption"></div>
              </div>

              {features.map((feature => (
                <div className="priceRow">
                  <div className="featureNameCont">
                    {renderValue(feature.featureName)}
                  </div>
                  <div className="accOption centerElm">
                    {renderValue(feature.growth)}
                  </div>
                  <div className="accOption centerElm">
                    {renderValue(feature.pro)}
                  </div>
                  <div className="accOption centerElm">
                    {/* {feature.business} */}
                    {renderValue(feature.business)}
                  </div>
                </div>
              )))}
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default MyAccount;