import React, { useState } from 'react'
import './KnowledgeBased.css';
import { Link } from 'react-router-dom';

import MandatorySettings from '../../../assets/mandatorySettings.svg';
import MandatoryPhone from '../../../assets/mandatoryPhone.svg';
import MandatoryName from '../../../assets/mandatoryName.svg';

import BusinessInitiated from '../../../assets/businessInitiate.svg';
import UserInitiated from '../../../assets/userInitiated.svg';
import LeftArrow from '../../../assets/leftArrow.svg';
import rightArrow from '../../../assets/rightArrow.svg';
import doubleArrowRight from '../../../assets/doubleArrowRight.svg';
import ProfileSvg from '../../../assets/profileName.svg';
import WhatsappSvg from '../../../assets/whatsapp.svg';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CellTowerIcon from '@mui/icons-material/CellTower';
import HistoryIcon from '@mui/icons-material/History';
import LinkIcon from '@mui/icons-material/Link';
import QuizIcon from '@mui/icons-material/Quiz';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import { t } from 'i18next';
const KnowledgeBased = () => {

  const limitCards = [
    {
      title: "",
      p1: "",
      value: "",
      p2: "",
    }
  ]

  const [activeTab, setActiveTab] = useState('knowledge');

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab
  };
  return (
    <div className='knowledgeBasedMain_cont'>
      <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'broadcast' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp"
          >
            <CellTowerIcon style={{ marginRight: "8px" }} /> {t('broadcast')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'history' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/message-history"
          >
            <HistoryIcon style={{ marginRight: "8px" }} /> {t('messageHistory')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'connect' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/connect-account"
          >
            <LinkIcon style={{ marginRight: "8px" }} /> {t('connectAccount')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'automation' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/automations"
          >
            <SettingsSuggestIcon style={{ marginRight: "8px" }} /> {t('automations')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'knowledge' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/knowledge"
          >
            <QuizIcon style={{ marginRight: "8px" }} /> {t('knowledgeBase')}
          </Link>
        </div>
      </div>
      <div className='mandatoryMain_cont'>
        <h1 className='knowledgeMainHead'>Mandatory Requirements</h1>
        <div className="mandatoryCards_cont">
          <div className="mandatoryCard mandatoryCard11">
            <div className="mandatorySvgMain_cont">
              <div className="mandatorySvg_cont">
                <img className='mandatoryImage' src={MandatorySettings} alt="" />
              </div>
            </div>

            <div className="mandatoryHead_cont">
              <h2 className='mandatoryCardHead'>Facebook Business Manager ID</h2>
            </div>
            <div className="mandatoryContent_cont">
              <p className='mandatoryPara'><PlayArrowIcon style={{ height: "15px" }} />Need a Facebook Business Manager Account? <a href="">Create New</a></p>
              <p className='mandatoryPara'><PlayArrowIcon style={{ height: "15px" }} />Is your  Facebook Business Manager Account not verified. <a href="">Verfy Now</a></p>
            </div>
          </div>
          <div className="mandatoryCard mandatoryCard12">
            <div className="mandatorySvgMain_cont">
              <div className="mandatorySvg_cont">
                <img className='mandatoryImage' src={MandatoryPhone} alt="" />
              </div>
            </div>
            <div className="mandatoryHead_cont">
              <h2 className='mandatoryCardHead'>Business Number</h2>
            </div>
            <div className="mandatoryContent_cont">
              <p className='mandatoryPara'><PlayArrowIcon style={{ height: "15px" }} />Mobile number must not have any active whatsapp account at the time of API migration</p>
              <p className='mandatoryPara'><PlayArrowIcon style={{ height: "15px" }} />Want to delete the existing Whatsapp Account from the number? <a href="">Learn how to delete</a></p>
            </div>
          </div>
          <div className="mandatoryCard mandatoryCard13">
            <div className="mandatorySvgMain_cont">
              <div className="mandatorySvg_cont">
                <img className='mandatoryImage' src={MandatoryName} alt="" />
              </div>
            </div>
            <div className="mandatoryHead_cont">
              <h2 className='mandatoryCardHead'>Display Name</h2>
            </div>
            <div className="mandatoryContent_cont">
              <p className='mandatoryPara'><PlayArrowIcon style={{ height: "15px" }} />Display Name must have a clear relation with the business. PNeed a Facebook Business Manager Account? Create New</p>
              <p className='mandatoryPara'><PlayArrowIcon style={{ height: "15px" }} />Display Name must adhere to Whatsapp Display Name Guidelines. <a href="">Read Guidelines</a></p>
            </div>
          </div>
        </div>
      </div>

      <div className="messageLimitsMain_cont">
        <h1 className='knowledgeMainHead'>Broadcast/Template Message Limits <span style={{ fontWeight: "300" }}> (Set By WhatsApp)</span></h1>
        <h3 className='knowledgeSubHead' >All businesses are classified under 4 different categories = Tier 1/2/3/4</h3>
        <div className="limitsCards_cont">
          <div className="limitsCard limitsCard11">
            <div className="limitsCardHead_cont limitsCardHead_cont1">
              <h3 className='limitsCardHead'>Sandbox Tier</h3>
            </div>
            <div className="limitsCardContent_cont">
              <p className='limitsPara'>Can send broadcast messages to</p>
              <h2 className='limitsData'>250</h2>
              <p className='limitsPara'>customers in 24 hours</p>
            </div>
          </div>
          <div className="limitsCard limitsCard12">
            <div className="limitsCardHead_cont limitsCardHead_cont2">
              <h3 className='limitsCardHead'>Tier 1</h3>
            </div>
            <div className="limitsCardContent_cont">
              <p className='limitsPara'>Can send broadcast messages to</p>
              <h2 className='limitsData'>1K</h2>
              <p className='limitsPara'>customers in 24 hours</p>
            </div>
          </div>
          <div className="limitsCard limitsCard13">
            <div className="limitsCardHead_cont limitsCardHead_cont3">
              <h3 className='limitsCardHead'>Tier 2</h3>
            </div>
            <div className="limitsCardContent_cont">
              <p className='limitsPara'>Can send broadcast messages to</p>
              <h2 className='limitsData'>10K</h2>
              <p className='limitsPara'>customers in 24 hours</p>
            </div>
          </div>
          <div className="limitsCard limitsCard14">
            <div className="limitsCardHead_cont limitsCardHead_cont4">
              <h3 className='limitsCardHead'>Tier 3</h3>
            </div>
            <div className="limitsCardContent_cont">
              <p className='limitsPara'>Can send broadcast messages to</p>
              <h2 className='limitsData'>100K</h2>
              <p className='limitsPara'>customers in 24 hours</p>
            </div>
          </div>
          <div className="limitsCard limitsCard15">
            <div className="limitsCardHead_cont limitsCardHead_cont5">
              <h3 className='limitsCardHead'>Tier 4</h3>
            </div>
            <div className="limitsCardContent_cont">
              <p className='limitsPara'>Can send broadcast messages to</p>
              <h2 className='limitsData'>Unlimited</h2>
              <p className='limitsPara'>customers in 24 hours</p>
            </div>
          </div>
        </div>

        <div className="guidelineLinksCont">
          <p>For more information: <a href="https://developers.facebook.com/docs/whatsapp/messaging-limits/" target='_blank'>https://developers.facebook.com/docs/whatsapp/messaging-limits/</a></p>
        </div>
      </div>

      <div className="typeConvMain_cont">
        <h1 className="knowledgeMainHead">Types of Conversation on Whatsapp API</h1>
        <div className="typeConvCards_cont">
          <div className="typeConvCard typeConvCard1">
            <div className="typeConvCardHead_cont typeConvCardHead_cont1">
              <h3 className='typeConvCardHead'>Business Initiated (Outgoing conversation)</h3>
            </div>
            <div className="typeConvContentMain_cont">
              <div className="typeSvg_cont">
                <img className='' src={BusinessInitiated} alt="" />
              </div>
              <div className="typeConvContent_cont">
                <p className='typeConvPara'><PlayArrowIcon style={{ height: "15px" }} />Broadcast/Template Messages/ Notifications</p>
                <p className='typeConvPara'><PlayArrowIcon style={{ height: "15px" }} />Marketing</p>
                <p className='typeConvPara'><PlayArrowIcon style={{ height: "15px" }} />Utility</p>
              </div>
            </div>
          </div>
          <div className="typeConvCard typeConvCard2">
            <div className="typeConvCardHead_cont typeConvCardHead_cont2">
              <h3 className='typeConvCardHead'>User Initiated (Incoming conversation)</h3>
            </div>
            <div className="typeConvContentMain_cont">
              <div className="typeSvg_cont">
                <img className='' src={UserInitiated} alt="" />
              </div>
              <div className="typeConvContent_cont">
                <p className='typeConvPara'><PlayArrowIcon style={{ height: "15px" }} />User Inquiries & User Replies</p>
                <p className='typeConvPara'><PlayArrowIcon style={{ height: "15px" }} />Service Conversations</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="chargingModelMain_cont">
        <h1 className="knowledgeMainHead">Charging Model in Whatsapp API Conversation Based Pricing (CBP)</h1>
        <div className="chargingModelCards_cont">
          <div className="chargingModelCard chargingModelCard11">
            <p className='chargingModelCardHead'>A session begins when the business initiates a conversation with the customer.</p>
            <p className='chargingModelCardPara'>Each session is timed for 24 hours.</p>
          </div>
          <div className="chargingArrow_cont">
            <img src={doubleArrowRight} alt="" />
          </div>
          <div className="chargingModelCard chargingModelCard12">
            <p className='chargingModelCardHead'>The 1000 UIC/sessions are free every month.</p>
            <p className='chargingModelCardPara'>You can send + recieve multiple messages during these sessions at zero cost.</p>
          </div>
          <div className="chargingArrow_cont">
            <img src={doubleArrowRight} alt="" />
          </div>
          <div className="chargingModelCard chargingModelCard13">
            <p className='chargingModelCardHead'>After the 1000 UIC: </p>
            <p className='chargingModelCardHead'>Incoming messages will be charged.</p>
            <p className='chargingModelCardPara'>Check price here</p>
          </div>
        </div>
        <div className="chargingModelSec_cont">
          <div className="comCont1">
            <img src={BusinessInitiated} alt="" />
          </div>
          <div className="comCont2">
            <img src={rightArrow} alt="" />
            <p>One Conversation / Session = 24 Hours</p>
            <img src={LeftArrow} alt="" />
          </div>
          <div className="comCont3">
            <img src={UserInitiated} alt="" />
          </div>

        </div>
      </div>

      <div className="whatsappCostsMain_cont">
        <h1 className="knowledgeMainHead">WhatsApp API Costs are divided into 2 segments</h1>
        <div className="whatsappCostsCards_cont">
          <div className="whatsappCostsCard whatsappCostsCard1">
            <div className="costCardImg_cont">
              <img src={WhatsappSvg} alt="" />
            </div>
            <div className="costCardHead_cont">
              <h2>Messaging Charges</h2>
            </div>
            <div className="costCardContent_cont">
              <p className='whatsappCostsPara'><PlayArrowIcon style={{ height: "15px" }} />META charges you per 24h session</p>
              <p className='whatsappCostsPara'><PlayArrowIcon style={{ height: "15px" }} />No charge per message, only per session</p>
              <p className='whatsappCostsPara'><PlayArrowIcon style={{ height: "15px" }} />Messaging session is also called 'Conversation'</p>
              <p className='whatsappCostsPara'><PlayArrowIcon style={{ height: "15px" }} />One session can be Business Initiated (BIC) or Customer Initiated (UIC)</p>
              <p className='whatsappCostsPara'><PlayArrowIcon style={{ height: "15px" }} />Charged from Prepaid Wallet credits</p>
            </div>

          </div>
          <div className="whatsappCostsCard whatsappCostsCard2">
            <div className="costCardImg_cont">
              <img src={ProfileSvg} alt="" />

            </div>
            <div className="costCardHead_cont">
              <h2>ZUMI software charges</h2>
            </div>
            <div className="costCardContent_cont">
              <p className='whatsappCostsPara'><PlayArrowIcon style={{ height: "15px" }} />Fixed monthly software fee</p>
              <p className='whatsappCostsPara'><PlayArrowIcon style={{ height: "15px" }} />Plans starts from monthly INR 2499 / $49</p>
              <p className='whatsappCostsPara'><PlayArrowIcon style={{ height: "15px" }} />Every software inclues 5 user logins free</p>
              {/* <p className='whatsappCostsPara'><PlayArrowIcon style={{ height: "15px" }} /></p>
              <p className='whatsappCostsPara'><PlayArrowIcon style={{ height: "15px" }} /></p> */}
            </div>
          </div>

        </div>

      </div>

    </div>
  )
}

export default KnowledgeBased;