import React, { useState, useEffect, useContext } from 'react';
import './Automation.css';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import emptyReplyMat from '../../assets/emptyReplyMat.svg';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import NewText from './NewText/NewText';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from '@mui/material';
import NewKeyword from './NewKeyword/NewKeyword';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  height: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};


const keywordModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  height: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};


const Automation = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const [activeTab, setActiveTab] = useState('material');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [keywordOpen, setKeywordOpen] = React.useState(false);
  const handleKeywordOpen = () => setKeywordOpen(true);
  const handleKeywordClose = () => setKeywordOpen(false);

  const replyMaterial = [
    {
      id: 1,
      name: 'For "Interested" keyword',
      description: "Great to hear you're interested! Would you like more details or have any specific questions? I'm here to help!",

    },
    {
      id: 2,
      name: 'Material 2',
      description: 'This is material 2',

    },
    {
      id: 3,
      name: 'Material 3',
      description: 'This is material 3',

    },
    {
      id: 4,
      name: 'Material 4',
      description: 'This is material 4',

    },
  ]

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab
  };


  return (
    <div className='atmnMain_cont'>
      <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <div
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'material' ? 'automationtabActive' : ''}`}
            onClick={() => handleTabClick('material')}
          >
            <QuickreplyIcon style={{ marginRight: "8px" }} /> Reply Material
          </div>
          <div
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'keyword' ? 'automationtabActive' : ''}`}
            onClick={() => handleTabClick('keyword')}
          >
            <CallToActionIcon style={{ marginRight: "8px" }} /> Keyword Action
          </div>
        </div>


      </div>

      {activeTab === "material" && (
        <div className="replyMatMain_cont">
          <div className='searchAndbtn_cont'>
            <div className="searchTemplate_row">
              <div className="searchSortTemplate_cont">
                {/* <TextField
                className='searchTemplateInput'
                id="outlined-size-small"
                size="small"
                placeholder='Search Templates'
              // onChange={handleSearch}
              /> */}
              </div>

              <div className="templateRowBtn_cont">
                <button className='btnFill' onClick={handleOpen}>
                  Add New
                </button>

                {/* M O D A L   T O   C R E A T E   R E P L Y   M A T E R I A L */}
                <Modal
                  className='templateStyleMobile'
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <NewText closeModal={handleClose}></NewText>
                  </Box>
                </Modal>

              </div>
            </div>
          </div>

          {/* N O    R E P L Y   M A T E R I A L   P R E S E N T */}
          {replyMaterial.length === 0 && (
            <div className="noReplyMaterialCont">
              <img src={emptyReplyMat} alt="" />
            </div>
          )}

          {/*  R E P L Y   M A T E R I A L    P R E S E N T */}
          <div className="replyMat_cont">

            {replyMaterial.map((item) => {
              return (
                <div key={item.id} className="replyMatCardMain_cont">
                  <div className="replyMatTitle_cont">
                    <div className="replyMatTitle">
                      {/* <p>{item.name}</p> */}
                      <p>{item.name.length > 26 ? `${item.name.slice(0, 26)}...` : item.name}</p>
                    </div>
                    <div className="replyMatBtns_cont">
                      <div className="replyMatBtn_cont centerElm" style={{ marginRight: "15px" }}>
                        <EditIcon style={{ fontSize: "20px" }} />
                      </div>
                      <div className="replyMatBtn_cont centerElm">
                        <DeleteIcon style={{ fontSize: "20px" }} />
                      </div>
                    </div>
                  </div>
                  <div className="replyMatDesc_cont">
                    <p>{item.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}


      {activeTab === "keyword" && (
        <div className="keywordActionMain_cont">
          <div className='searchAndbtn_cont'>
            <div className="searchTemplate_row">
              <div className="searchSortTemplate_cont">
                <TextField
                  className='searchTemplateInput'
                  id="outlined-size-small"
                  size="small"
                  placeholder='Search...'
                // onChange={handleSearch}
                />
              </div>

              <div className="templateRowBtn_cont">
                <button className='btnFill' onClick={handleKeywordOpen}>
                  New Keyword
                </button>

                {/* M O D A L   T O   C R E A T E   T E M P L A T E */}
                <Modal
                  className='templateStyleMobile'
                  open={keywordOpen}
                  onClose={handleKeywordClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={keywordModalStyle}>
                    <NewKeyword replyMaterial={replyMaterial} closeModal={handleKeywordClose}></NewKeyword>
                  </Box>
                </Modal>

              </div>
            </div>
          </div>

          {/* N O    K E Y W O R D    A C T I O N   P R E S E N T */}
          {replyMaterial.length === 0 && (
            <div className="noReplyMaterialCont">
              <img src={emptyReplyMat} alt="" />
            </div>
          )}

          {/*  K E Y W O R D    A C T I O N   P R E S E N T */}

          <div className="keyAct_cont">
            <div className="keyActCardMain_cont keyActHeadCard">

              <div className="keywordName_cont centerElm">
                Keyword
              </div>
              <div className="keyTriggered_cont centerElm">
                Triggered
              </div>
              <div className="matchingMethod_cont centerElm">
                Matching method
              </div>
              <div className="replyMaterial_cont centerElm">
                Reply Material
              </div>
              <div className="keyActions_cont centerElm">
                Actions
              </div>
            </div>
            {replyMaterial.map((item) => {
              return (
                <div key={item.id} className="keyActCardMain_cont">
                  <div className="keywordName_cont centerElm">
                    Keyword Name
                  </div>
                  <div className="keyTriggered_cont centerElm">
                    88
                  </div>
                  <div className="matchingMethod_cont centerElm">
                    Fuzzy 90%
                  </div>
                  <div className="replyMaterial_cont centerElm">
                    djf df jds
                  </div>

                  <div className="keyActions_cont centerElm">
                    Action
                  </div>
                </div>
              )
            })}

          </div>
        </div>
      )}


    </div>
  )
}

export default Automation;