import React, { useEffect, useState } from 'react';
import './EbayAuthSuccess.css';
import check from '../../../../assets/greenCheck.jpg';
import cross from '../../../../assets/redCross.jpg';
import { useTranslation } from 'react-i18next';

const EbayAuthSuccess = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const [authStatus, setAuthStatus] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ebayUserAuth_URL = `${baseUrl}/zumi/api/ebay-callback`;
    const code = params.get('code');
    // const selling_partner_id = params.get('selling_partner_id');
    const marketRegion = localStorage.getItem("marketRegion");
    const ebayAccountName = localStorage.getItem("ebayAccountName");
    const ebaySellerEmail = localStorage.getItem("ebaySellerEmail");

    if (code && marketRegion && ebayAccountName && ebaySellerEmail) {
      const authInfo = {
        spapi_oauth_code: code,
        region: marketRegion,
        nickname: ebayAccountName,
        email: ebaySellerEmail,
      };

      const authenticateUser = async () => {
        try {
          const response = await fetch(ebayUserAuth_URL, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(authInfo),
          });

          if (response.ok) {
            console.log('Ebay authentication successful.');
            setAuthStatus(true);
          } else {
            console.error('Failed to authorize. Status:', response.status);
            setAuthStatus(false);
          }
        } catch (error) {
          console.error('Error:', error);
          setAuthStatus(false);
        }
      };
      authenticateUser();
    }
  }, []);

  const [show, setShow] = useState(false);

  useEffect(() => {
    // Simulate a successful check
    setTimeout(() => {
      setShow(true);
    }, 100); // Adjust timing as needed
  }, []);

  return (
    <div className='ebayAuthMain_cont'>
      {authStatus === true && (
        <div className="ebayAuthSub_cont1">
          <div className={`ebayAuthImg_cont centerElm ${show ? 'show' : ''}`}>
            <img src={check} alt="" />
          </div>
          <p>Ebay Authorization Successful</p>
          <button className='btnNotFill ebayAuthBtn'>View Marketplace</button>
        </div>
      )}

      {authStatus === false && (
        <div className="ebayAuthSub_cont2">
          <div className={`ebayAuthImg_cont centerElm ${show ? 'show' : ''}`}>
            <img src={cross} alt="" />
          </div>
          <p>Ebay Authorization Failed</p>
          <button className='btnNotFill ebayAuthBtn'>Try Again</button>
        </div>
      )}
    </div>
  );
};

export default EbayAuthSuccess;