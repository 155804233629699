import React, { useEffect, useState } from 'react';
import './TaskView.css';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';

const TaskView = ({ viewTaskId, closeModal, taskData }) => {

  const { t } = useTranslation();
  const authToken = localStorage.getItem('authToken');
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [taskDetails, setTaskDetails] = useState(taskData);

  const [viewTaskLoader, setViewTaskLoader] = useState(true);

  const columns = [
    // {
    //   name: t("campaignName"),
    //   selector: row => row.campaignName,
    // },
    {
      name: t("taskName"),
      selector: row => row.taskName,

    },
    {
      name: t("senderCli"),
      selector: row => row.cli,
    },
    {
      name: "Start Date",
      // selector: row => row.created_date,
      selector: row => {
        const date = new Date(row.scheduleStartDate);
        return date.toLocaleString('en-US', {
          // weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour12: true
        });
      },
    },
    {
      name: "End Date",
      // selector: row => row.created_date,
      selector: row => {
        const date = new Date(row.scheduleEndDate);
        return date.toLocaleString('en-US', {
          // weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour12: true
        });
      },
    },
    {
      name: t("taskStatus"),
      selector: row => row.statusDesc,
    },

  ];

  const sentMessagesStyles = {
    headRow: {
      style: {
        position: "sticky",
        top: 0,
        color: "black",
        width: "100%",
        // border: "1px solid green",
        fontSize: "1rem",
        lineHeight: "27px",
      },
    },
    headCells: {
      style: {
        padding: "10px",
        textAlign: "left",
        width: "100%",
      },
    },
    cells: {
      style: {
        padding: "10px",
        fontSize: "0.9rem",
        fontFamily: "Poppins, Montserrat",
        color: "black",
        lineHeight: "25px",
      },
    }
  }

  // F E T C H   T A S K  D A T A 
  useEffect(() => {
    if (authToken && viewTaskId) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${baseUrl}/airkommWebPortal/api/gettasksdetails?campaignId=${viewTaskId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            setViewTaskLoader(false)
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setTaskDetails(result);
          setViewTaskLoader(false);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, []);

  // useEffect(() => {
  //   if (taskDetails) {
  //     console.log("Task details : ", taskDetails);
  //   }
  // }, [taskDetails]);

  return (
    <div className='taskViewMain_cont'>
      <div className="newSegmentHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('viewTask')}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="viewTaskMain">

        <div className="viewTaskSearch_cont">

        </div>
        <div className="viewTaskTable_cont">
          <div className="viewTask_cont">

            {viewTaskLoader ? (
              <ContactTableLoader />
            ) : (
              <DataTable
                columns={columns}
                customStyles={sentMessagesStyles}
                data={taskDetails}
                pagination
              >
              </DataTable>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaskView;