import React, { useState, useEffect, useContext, useMemo } from 'react';
import './CreateBroadcast.css';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import { TextField } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
// import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import SegmentContext from '../../../Context/SegmentContext';

import Alert from '@mui/material/Alert';
import Papa from 'papaparse';

const CreateBroadcast = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem("authToken");
  const createBroadcast_URL = `${baseUrl}/zumi/api/createbroadcost`;
  const [selectedQuoteFile, setSelectedQuoteFile] = useState(null);
  const [selectedContactFile, setSelectedContactFile] = useState(null);

  const [successAlert, setSuccessAlert] = useState(false);
  const [failedAlert, setFailedAlert] = useState(false);

  const segment_URL = `${baseUrl}/zumi/api/getsegment`;
  const [segmentData, setSegmentData] = useState([]);
  const { segmentArray, setSegmentArray } = useContext(SegmentContext);

  const getSupplier_URL = `${baseUrl}/zumi/api/getsupplier`;
  const [supplierDetails, setSupplierDetails] = useState();

  const [quoteFileData, setQuoteFileData] = useState(null);
  const [finalQuoteData, setFinalQuoteData] = useState([]);

  const [loader, setLoader] = useState(false);

  const [totalPrice, setTotalPrice] = useState();
  const [totalDealerPrice, setTotalDealerPrice] = useState();

  const [createBroadcastData, setCreateBroadcastData] = useState({
    dealerType: "Corporate",
    supplierId: 2,
    supplierName: "",
    email: "",
    phone: "",
    messageTitle: "",
    messageContent: "This is a quotation from ",
    address: "",
    markup: "",
    segmentId: 0,
  });

  // H A N D L E   Q U O T E   F I L E 
  const handleQuoteFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedQuoteFile(file);
  };

  // H A N D L E  C O N T A C T   F I L E 
  const handleContactFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedContactFile(file);
  };

  // H A N D L E   C H A N G E   F U N C T I O N 
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateBroadcastData((prevData) => ({ ...prevData, [name]: value }));
  }

  // F E T C H  S U P P L I E R   D E T A I L S 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(getSupplier_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setSupplierDetails(result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // F U N C T I O N    T O   U P D A T E   C R E A T E    B R O A D C A S T    D A T A 
  useEffect(() => {
    if (supplierDetails) {
      setCreateBroadcastData(prevData => ({
        ...prevData,
        supplierId: supplierDetails.supplierId,  // Assuming supplierDetails has an 'id' field for supplierId
        supplierName: supplierDetails.supplierName,
        email: supplierDetails.email,
        phone: supplierDetails.phone,
        messageContent: `This is a quotation from ${supplierDetails.email}`,  // Concatenating email
      }));
    }
  }, [supplierDetails]);

  // F E T C H   S E G M E N T S   D A T A 
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(segment_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setSegmentData(result);
        } catch (error) {
          console.log(error);
        }

      };
      fetchData();
    }
  }, []);

  // S E T T I N G   S E G M E N T   C O N T E X T 
  useEffect(() => {
    if (segmentData) {
      setSegmentArray(segmentData);
    }
  }, [segmentArray, segmentData]);

  // H A N D L E   P R O C E S S   F U N C T I O N
  const handleProcess = () => {
    if (selectedQuoteFile) {
      // Parse the CSV file using PapaParse
      Papa.parse(selectedQuoteFile, {
        complete: (result) => {
          const parsedData = result.data;

          // Filter out empty rows (rows with all properties as undefined or empty)
          const cleanedData = parsedData.filter(row =>
            Object.values(row).some(value => value && value !== 'N/A') // Keep rows with valid values
          );

          // Update the cleaned data by calculating dealer price
          const updatedData = cleanedData.map(row => {
            const dealerPrice = row['price']
              ? (parseFloat(row['price']) * (1 + createBroadcastData.markup / 100)).toFixed(2)
              : '0'; // Set a default value if 'price' is not available

            // Return the row with an added 'dealerPrice' field, excluding any empty properties
            const updatedRow = { ...row, 'dealerPrice': dealerPrice };

            // Remove any properties that are undefined or empty
            Object.keys(updatedRow).forEach(key => {
              if (updatedRow[key] === undefined || updatedRow[key] === '') {
                delete updatedRow[key];
              }
            });

            return updatedRow;
          });


          setQuoteFileData(parsedData); // Keep the original data if needed
          setFinalQuoteData(updatedData); // Store the updated data with dealer price
        },
        header: true, // If the first row contains headers
      });
    }
  };

  // F U N C T I O N   T O    S E T   T O T A L P R I C E    A N D   T O T A L D E A L E R P R I C E
  useEffect(() => {
    // Check if finalQuoteData is not null or undefined and is an array
    if (Array.isArray(finalQuoteData) && finalQuoteData.length > 0) {
      const lastItem = finalQuoteData[finalQuoteData.length - 1];
      if (lastItem.price !== undefined && lastItem.dealerPrice !== undefined) {
        setTotalPrice(lastItem.price);
        setTotalDealerPrice(lastItem.dealerPrice);
      }
    }
  }, [finalQuoteData]);

  // S U B M I T   F U N C T I O N 
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoader(true);

    const formData = new FormData();

    // Append form data
    for (const key in createBroadcastData) {
      formData.append(key, createBroadcastData[key]);
    }
    // remove last object from finalQuoteData
    if (finalQuoteData && finalQuoteData.length > 0) {
      finalQuoteData.pop(); // Remove the last element
    }
    // append quote data
    const quoteDataJson = JSON.stringify(finalQuoteData);
    if (finalQuoteData) formData.append('productList', quoteDataJson);
    // append totalPrice
    formData.append('totalPrice', totalPrice);
    // append totalDealerPrice
    formData.append('totalDealerPrice', totalDealerPrice);
    // append contact file
    const contactFile = selectedContactFile || new File([], "");
    formData.append('uploadContact', contactFile);


    // Log FormData for testing
    for (let [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(key, value.name); // Display file name instead of file object
      } else {
        console.log(key, value); // Display value of other fields
      }
    }

    try {
      // Perform the fetch request
      const response = await fetch(createBroadcast_URL, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${authToken}`, // Authorization header
        },
      });

      if (response.ok) {
        setSuccessAlert(true);
        setLoader(false);
      } else {
        setLoader(false);
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      setFailedAlert(true);
      console.error('Submission error:', error);
    } finally {
      setCreateBroadcastData({
        dealerType: "",
        supplierId: 1,
        supplierName: "",
        email: "",
        phone: "",
        address: "",
        markup: "",
        segmentId: "",
      });

      setSelectedQuoteFile(null);
      setSelectedContactFile(null);
      setQuoteFileData(null);
      setFinalQuoteData(null);
      setLoader(false);
    }
  };

  // H A N D L E   S A M P L E   Q U O T E   F I L E   D O W N L O A D
  const handleQuoteFileDownload = async () => {
    try {
      const response = await fetch(`${baseUrl}/zumi/resellerQuotationSampleFile.csv`,
        // const response = await fetch(`http://localhost:3000/resellerQuotationSampleFile.csv`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'resellerQuotationSampleFile.csv');        // file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  // H A N D L E   S A M P L E   C O N T A C T S   F I L E   D O W N L O A D
  const handleContactFileDownload = async () => {
    try {
      const response = await fetch(`${baseUrl}/zumi/resellerContactSampleFile.csv`,
        // const response = await fetch(`http://localhost:3000/resellerContactSampleFile.csv`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'resellerContactSampleFile.csv');        // file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  const isSubmitDisabled =
    !createBroadcastData.dealerType ||
    !createBroadcastData.supplierId ||
    !createBroadcastData.supplierName ||
    !createBroadcastData.email ||
    !createBroadcastData.phone ||
    !createBroadcastData.messageTitle ||
    !createBroadcastData.messageContent ||
    !createBroadcastData.markup ||
    !selectedQuoteFile ||
    (!selectedContactFile && !createBroadcastData.segmentId);

  return (
    <div className='createBroadcastMain_cont'>
      <Breadcrumbs />
      <div className="createBroadcast_cont">

        {/* Row 1 */}
        <div className="createBroadcastRow">
          <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
            <label htmlFor="dealerType" className="welcomeFormLabel">Dealer Type  <span className="asterisk">*</span></label>
            <Select
              autoComplete='off'
              className='filterSelect'
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="dealerType"
              value={createBroadcastData.dealerType}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected) {
                  return selected;
                }
                return <em>Dealer Type</em>
              }}
            >
              <MenuItem value="Corporate">Corporate</MenuItem>
              <MenuItem value="Individual">Individual</MenuItem>
            </Select>
          </div>
          <div className="createBroadcastInput_cont">
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('dealerName')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              disabled
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='text'
              name="supplierName"
              id="outlined-basic"
              placeholder="Enter name"
              variant="outlined"
              value={createBroadcastData.supplierName}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* Row 2 */}
        <div className="createBroadcastRow">
          <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('email')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              disabled
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='email'
              name="email"
              id="outlined-basic"
              placeholder="Enter email"
              variant="outlined"
              value={createBroadcastData.email}
              onChange={handleChange}
            />
          </div>
          <div className="createBroadcastInput_cont" >
            <label htmlFor="contactPerson" className="welcomeFormLabel">Whatsapp Number  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              disabled
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='number'
              name="phone"
              id="outlined-basic"
              placeholder="Enter phone"
              variant="outlined"
              value={createBroadcastData.phone}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Row 3 */}
        {/* <div className="createBroadcastRow">
          <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">Dealer Address  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='text'
              name="address"
              id="outlined-basic"
              placeholder="Enter address"
              variant="outlined"
              value={createBroadcastData.address}
              onChange={handleChange}
            />
          </div>
          <div className="createBroadcastInput_cont">
            <label htmlFor="markup" className="welcomeFormLabel">Markup  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='text'
              name="markup"
              id="outlined-basic"
              placeholder="Enter markup"
              variant="outlined"
              value={createBroadcastData.markup}
              onChange={handleChange}
            />
          </div>
        </div> */}

        <div className="createBroadcastRow">
          <div className="broadcastRow_cont1">
            <div className="createBroadcastInput_cont" style={{ marginRight: "20px", marginBottom: "8px" }}>
              <label htmlFor="contactPerson" className="welcomeFormLabel">Message Title  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                type='text'
                name="messageTitle"
                id="outlined-basic"
                placeholder="Enter message title"
                variant="outlined"
                value={createBroadcastData.messageTitle}
                onChange={handleChange}
              />
            </div>
            <div className="createBroadcastInput_cont">
              <label htmlFor="markup" className="welcomeFormLabel">Markup  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                type='number'
                name="markup"
                id="outlined-basic"
                placeholder="Enter markup"
                variant="outlined"
                value={createBroadcastData.markup}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="broadcastRow_cont2">
            <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
              <label htmlFor="contactPerson" className="welcomeFormLabel">Message Content  <span className="asterisk">*</span></label>
              <TextField
                className='welcomeFormInput'
                multiline
                rows={5}
                InputLabelProps={{ style: { display: 'none' } }}
                inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                type='text'
                name="messageContent"
                id="outlined-basic"
                placeholder="Enter message content"
                variant="outlined"
                value={createBroadcastData.messageContent}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        {/* Row 4 */}
        <div className="createBroadcastRow">
          <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('uploadQuote')}  <span className="asterisk">*</span></label>
            <input
              type='file'
              accept=".csv, .xlsx, .xls"
              className='uploadFileBtn'
              name="uploadQuotation"
              id="uploadQuoteBtn"
              onChange={handleQuoteFileChange}
            />
            <div className="createBroadCastFileLabel_cont">
              <label htmlFor="uploadQuoteBtn" className='uploadQuoteLabel'>
                Choose File
              </label>
              <div className="selectedQuoteFile_cont">
                {selectedQuoteFile && <p style={{ color: "green" }}>{selectedQuoteFile.name}</p>}
              </div>
            </div>
          </div>
          <div className="createBroadcastInput_cont">
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('uploadContact')}  <span className="asterisk">*</span></label>
            <div className="demoInputMUI">
              <input
                // disabled={createBroadcastData.segmentId}
                type='file'
                accept=".csv, .xlsx, .xls"
                className='uploadFileBtn smsInputField'
                name="smsContactFile"
                id="uploadBtn"
                onChange={handleContactFileChange}
              />
              <label
                htmlFor="uploadBtn"
                className='uploadSmsFileLabel'
                // style={{
                //   border: createBroadcastData.segmentId ? "1px solid #EEEEEE" : "#17c1e8",
                //   backgroundColor: createBroadcastData.segmentId ? '#EEEEEE' : '#17c1e8',
                //   color: createBroadcastData.segmentId ? 'gray' : '#fff',
                // }}
                style={{ background: '#17c1e8', border: "none", color: "#fff" }}
              >
                Choose File
              </label>
              <div className="fileName_cont" id="fileName_cont">
                {selectedContactFile ? (
                  <p><span className='fileName' style={{ fontSize: "12px" }}>{selectedContactFile.name}</span> </p>
                ) : <p className='nofile' style={{ fontSize: "12px" }}>{t("noFileSelected")}</p>}
              </div>
              <div className="orContainer centerElm">or</div>
              <Select
                // disabled={selectedContactFile}
                className="selectSegmentSms"
                name='segmentId'
                size='small'
                value={createBroadcastData.segmentId}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => {
                  const selectedSegment = segmentArray.find(item => item.segmentId === selected);
                  return selectedSegment ? selectedSegment.segmentName : <em>{t("selectSegmentPlaceholder")}</em>;
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 230,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                {
                  segmentArray.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.segmentId}>{item.segmentName}</MenuItem>
                    )
                  })
                }
              </Select>
            </div>
          </div>
        </div>

        {/* Row 5 */}
        <div className="processBroadcastBtn_cont">
          <div className="createBroadcastHelpLinks_cont">
            <div className="helpAnchorCont">
              <Link className='helpAnchor' onClick={handleQuoteFileDownload}>
                {t('downloadQuoteFile')}
                <DownloadIcon className='launchIcon' style={{ width: "18px", height: "18px", marginLeft: "5px" }} />
              </Link>
            </div>
            <div className="helpAnchorCont" style={{ marginRight: "0px" }}>
              <Link className='helpAnchor' onClick={handleContactFileDownload}>
                {t('downloadContactFile')}
                <DownloadIcon className='launchIcon' style={{ width: "18px", height: "18px", marginLeft: "5px" }} />
              </Link>
            </div>
          </div>
          <button
            className='btnFill'
            onClick={handleProcess}
            disabled={isSubmitDisabled}
            style={{
              border: isSubmitDisabled ? "1px solid #EEEEEE" : "#17c1e8",
              backgroundColor: isSubmitDisabled ? '#EEEEEE' : '#17c1e8',
              color: isSubmitDisabled ? 'gray' : '#fff',
            }}
          >
            Process
          </button>
        </div>
        <div className="loginAlert_cont">
          {successAlert &&
            <Alert severity="success" className='loginAlert' id='incorrectAlert'>Broadcast Successfull.</Alert>
          }
          {failedAlert &&
            <Alert severity="error" className='loginAlert' id='incorrectAlert'>Broadcast Failed.</Alert>
          }
        </div>
      </div>

      {/* Quote file data */}
      {quoteFileData && (
        <div className="quoteFileDataMain_cont">
          <p>Quote File Data: </p>
          <div className="quoteTableHead_cont">
            {quoteFileData[0] && Object.keys(quoteFileData[0])
              .filter(key => !['varient', 'input_link', 'valid_from', 'valid_upto'].includes(key))
              .map((key, index) => (
                <div className='quoteTableHead_col' key={index}>{key}</div>
              ))}
            <div className='quoteTableHead_col'>dealer_price {createBroadcastData.markup}%</div>
          </div>

          <div className="quoteTableBody_cont">
            {quoteFileData
              .filter(row => {
                return Object.keys(row).some(key => !['varient', 'input_link', 'valid_from', 'valid_upto'].includes(key) && row[key]);
              })
              .map((row, index) => (
                <div className='quoteTableRow' key={index}>
                  {Object.entries(row)
                    .filter(([key]) => !['varient', 'input_link', 'valid_from', 'valid_upto'].includes(key))
                    .map(([key, value], index) => (
                      <div className='quoteTableBody_col' key={index}>
                        {key === 'price' && (!value || value === '') ? "--" : value}
                      </div>
                    ))
                  }
                  <div className='quoteTableBody_col'>
                    <div className="dealerPrice centerElm">
                      {row['price'] ? (
                        (parseFloat(row['price']) * (1 + createBroadcastData.markup / 100)).toFixed(2)
                      ) : (
                        "--"
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className="createBroadcastBtn_cont">
            <button
              className='btnFill'
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
              style={{
                border: isSubmitDisabled ? "1px solid #EEEEEE" : "#17c1e8",
                backgroundColor: isSubmitDisabled ? '#EEEEEE' : '#17c1e8',
                color: isSubmitDisabled ? 'gray' : '#fff',
              }}
            >
              {t('submit')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default CreateBroadcast;